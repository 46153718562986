import { Injectable } from '@angular/core';
import { Dictionary } from '@ngrx/entity';
import { parsePhoneNumber } from 'libphonenumber-js';

import {
  AnswerFormatEnum,
  CardSortingResult,
  CompareTypeEnum,
  FabDictionaryItem,
  FabDictionaryItemStateEnum,
  FirstClickResult,
  FirstGlanceResult,
  FreeFormResult,
  LayoutGroupResult,
  MatrixResult,
  NumberResult,
  OperatorEnum,
  RankingResult,
  RowAnswerQuantityEnum,
  ScaleResult,
  SelectResult,
  Task,
  TaskGroupResult,
  TestItem,
  TestItemClickArea,
  TestItemResult,
  TestItemResultTypeEnum,
  TestItemTypeEnum,
} from 'src/api/testrunner/models';
import { EMPTY_GUID } from '../constants/constants';
import { InfoExtendedResult } from '../models/info-extended-result';
import { LogicResultEnum } from '../models/logic-result-enum';
import { TestItemModel } from '../models/test-item-model';
import { CompareHelperService, DiapasonValue, OperandInterface } from './compare-helper.service';
import { PersistenceService } from './persistence.service';

@Injectable({
  providedIn: 'root',
})
export class AnswerUtilsService {
  private _testItems: Dictionary<TestItem> = {};

  constructor(private persistenceService: PersistenceService) {}

  set testItems(testItems: Dictionary<TestItem>) {
    this._testItems = testItems;
  }

  getAnswerValues(answer: TestItemResult, isCustomAnswer = false, rowDictionaryItemId?: string): (string | number | boolean)[] {
    console.log(answer);
    switch (answer.type) {
      case TestItemResultTypeEnum.FirstClick: {
        const clickAreasIds = this.getClickAreas(
          {
            x: (answer as FirstClickResult).cursorPositionX ?? -1,
            y: (answer as FirstClickResult).cursorPositionY ?? -1,
          },
          answer?.testItemId ?? '',
        ).map((item) => item.fabDictionaryEntityId as string);
        return clickAreasIds.length
          ? clickAreasIds
          : (answer as FirstClickResult).cursorPositionX && (answer as FirstClickResult).cursorPositionY
            ? [EMPTY_GUID]
            : [];
      }
      case TestItemResultTypeEnum.FreeForm: {
        return (answer as FreeFormResult)?.elementStrings ?? [];
      }
      case TestItemResultTypeEnum.LayoutGroup:
      case TestItemResultTypeEnum.TaskGroup: {
        return [(answer as LayoutGroupResult | TaskGroupResult)?.logicResult ?? false];
      }
      case TestItemResultTypeEnum.Matrix: {
        return ((answer as MatrixResult)?.elementItems || [])
          .filter((matrixResultItem) => matrixResultItem.rowElementItemId === rowDictionaryItemId)
          .map((matrixResultItem) => matrixResultItem.columnElementItemId as string);
      }
      case TestItemResultTypeEnum.Number: {
        return (answer as NumberResult).answer !== null && (answer as NumberResult).answer !== undefined
          ? [(answer as NumberResult)?.answer as number]
          : [];
      }
      case TestItemResultTypeEnum.Scale: {
        return ((answer as ScaleResult)?.resultItems || [])
          .filter((scaleResultItem) => scaleResultItem.elementItemId === rowDictionaryItemId)
          .map((scaleResultItem) => scaleResultItem?.answer as number);
      }
      case TestItemResultTypeEnum.Select: {
        return isCustomAnswer
          ? [((answer as SelectResult)?.elementStrings ?? []).join('|')]
          : [
              ...((answer as SelectResult)?.elementRowItemIds ?? []),
              ...((answer as SelectResult)?.elementStrings?.length ? [EMPTY_GUID] : []),
            ];
      }
      default: {
        return [];
      }
    }
  }

  getClickAreas(clickCoords: { x: number; y: number }, testItemId: string): TestItemClickArea[] {
    return ((this._testItems[testItemId] as Task).answerClickAreas || []).filter(
      (area) =>
        area.topLeftX !== null &&
        area.topLeftX !== undefined &&
        area.topLeftY !== null &&
        area.topLeftY !== undefined &&
        area.width !== null &&
        area.width !== undefined &&
        area.height !== null &&
        area.height !== undefined &&
        clickCoords.x >= area.topLeftX &&
        clickCoords.x <= area.topLeftX + area.width &&
        clickCoords.y >= area.topLeftY &&
        clickCoords.y <= area.topLeftY + area.height,
    );
  }

  // Проверка ответа для разблокирования кнопки "Далее"
  isAnswerValid(testItemModel: TestItemModel, dictionaryItems: FabDictionaryItem[], ignoreVisibility: boolean = false): boolean {
    if ((!testItemModel.isShown && !ignoreVisibility) || !testItemModel.data?.answerRequired) {
      return true;
    }
    //@TODO Перепроверить условия валидации на заполнение обязательных ответов. true - ответ валиден, кнопка некст видима
    const answer = this.persistenceService.get('answer_' + testItemModel.id);

    switch (testItemModel.data.testItemType) {
      case TestItemTypeEnum.CardSorting: {
        const distributedCardCount = (answer as CardSortingResult)?.resultItems?.length ?? 0;
        const isGroupsNamesEmpty =
          testItemModel.data.isMustDefineNameForCustomCardGroups &&
          ((answer as CardSortingResult)?.resultItems || []).some(
            (resultItem) => !!resultItem.isCustomGroup && !resultItem.customGroupName,
          );
        const totalCardCount = testItemModel.data?.showedCardCount
          ? testItemModel.data?.showedCardCount
          : (testItemModel.data?.cards?.length ?? 0);
        const totalCardLimit =
          testItemModel.data?.cardsGroups?.length && testItemModel.data?.isCardsGroupsLimitEnabled
            ? testItemModel.data?.cardsGroups.reduce((limit, group) => limit + (group.maxCardsCount ? group.maxCardsCount : Infinity), 0)
            : Infinity;
        const isTotalFulfilled: boolean =
          distributedCardCount === totalCardCount || distributedCardCount === testItemModel.data.cards?.length;
        return !isGroupsNamesEmpty && (isTotalFulfilled || distributedCardCount === totalCardLimit);
      }
      case TestItemTypeEnum.FirstClick: {
        return (answer as FirstClickResult)?.cursorPositionX !== undefined && !(answer as FirstClickResult)?.cursorPositionY !== undefined;
      }
      case TestItemTypeEnum.FirstGlance: {
        return !!(answer as FirstGlanceResult)?.readingTimeSpentMs;
      }
      case TestItemTypeEnum.FreeForm: {
        if (answer?.isAnswerSkipped && testItemModel.data.isSkipButtonEnabled) {
          return true;
        }

        const elementStrings = ((answer as FreeFormResult)?.elementStrings || []).filter((value) => value.trim().length > 0);

        // проверка на email
        if (testItemModel.data?.answerFormat === AnswerFormatEnum.Email && elementStrings?.[0]) {
          const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w+)+$/i;
          return emailPattern.test(elementStrings[0]);
        }

        // проверка на номер телефона
        if (testItemModel.data?.answerFormat === AnswerFormatEnum.PhoneNumber && elementStrings?.[0]) {
          const parsedNumber = parsePhoneNumber(elementStrings?.[0]);
          return parsedNumber && parsedNumber.isValid();
        }

        // проверка на минимальную длину строки
        if (
          (testItemModel.data?.minValueIsEnabled &&
            testItemModel.data?.answerMinLength &&
            (!elementStrings.length || (elementStrings.length > 0 && elementStrings[0].length < testItemModel.data?.answerMinLength))) ||
          (!testItemModel.data.minValueIsEnabled && !elementStrings.length)
        ) {
          return false;
        }

        return !!answer || elementStrings.length > 0;
      }
      case TestItemTypeEnum.Info: {
        if (testItemModel.data?.privacyPolicyIsEnabled) {
          return !!answer && Boolean((answer as InfoExtendedResult).privacyPolicyResult);
        }
        return !!answer;
      }
      case TestItemTypeEnum.File: {
        if (answer?.isAnswerSkipped && testItemModel.data.isSkipButtonEnabled) {
          return true;
        }

        if (!answer) {
          return false;
        }
        return answer.resultFileData?.fileName && answer.resultFileData?.fileUrl;
      }
      case TestItemTypeEnum.Matrix: {
        if (answer?.isAnswerSkipped && testItemModel.data.isSkipButtonEnabled) {
          return true;
        }

        const matrix = testItemModel.data;

        const answerMinSelectedItems =
          matrix?.answerMinSelectedItemsEnabled && matrix?.answerQuantity === RowAnswerQuantityEnum.Multiple
            ? (matrix?.answerMinSelectedItems ?? 1)
            : 1;

        return dictionaryItems.reduce(
          (isAnswerRequired: boolean, row) =>
            isAnswerRequired &&
            ((answer as MatrixResult)?.elementItems || []).filter((elem) => elem.rowElementItemId === row.entityId).length >=
              answerMinSelectedItems,
          true,
        );
      }
      case TestItemTypeEnum.Number: {
        if (answer?.isAnswerSkipped && testItemModel.data.isSkipButtonEnabled) {
          return true;
        }

        return !!answer && (answer as NumberResult).answer !== null && (answer as NumberResult).answer !== undefined;
      }
      case TestItemTypeEnum.Ranking: {
        if (answer?.isAnswerSkipped && testItemModel.data.isSkipButtonEnabled) {
          return true;
        }

        const ranking = testItemModel.data;

        const elementRowItemIds = (answer as RankingResult)?.elementRowItemIds ?? [];

        const answerMinSelectedItems = ranking?.answerMinSelectedItemsEnabled ? ranking?.answerMinSelectedItems : 1;
        const answerMaxSelectedItems = ranking?.answerMaxSelectedItemsEnabled ? ranking?.answerMaxSelectedItems : null;

        return (
          ((!answerMinSelectedItems && elementRowItemIds.length >= dictionaryItems.length) ||
            (!!answerMinSelectedItems && elementRowItemIds.length >= answerMinSelectedItems)) &&
          (!answerMaxSelectedItems || elementRowItemIds.length <= answerMaxSelectedItems)
        );
      }
      case TestItemTypeEnum.Scale: {
        if (answer?.isAnswerSkipped && testItemModel.data.isSkipButtonEnabled) {
          return true;
        }

        const elementStrings = (answer as ScaleResult)?.resultItems ?? [];
        return (
          elementStrings.filter((answerItem) => answerItem.answer !== null && answerItem.answer !== undefined).length ===
          dictionaryItems.filter((dictionaryItem) => dictionaryItem.state !== FabDictionaryItemStateEnum.Delete).length
        );
      }
      case TestItemTypeEnum.Select: {
        if (answer?.isAnswerSkipped && testItemModel.data.isSkipButtonEnabled) {
          return true;
        }

        const elementStrings = ((answer as SelectResult)?.elementStrings || []).filter((value) => value.trim());
        const elementRowItemIds = ((answer as SelectResult)?.elementRowItemIds || []).filter((value) => value.trim());

        let answerMinSelectedItems: number | undefined;
        if (testItemModel.data?.answerQuantity === 'Multiple') {
          answerMinSelectedItems =
            testItemModel.data?.answerMinSelectedItemsEnabled && testItemModel.data?.answerMinSelectedItems !== undefined
              ? testItemModel.data?.answerMinSelectedItems
              : 1;
        } else {
          answerMinSelectedItems = 1;
        }

        return !!answerMinSelectedItems && elementStrings.length + elementRowItemIds.length >= answerMinSelectedItems;
      }
      default: {
        return true;
      }
    }
  }

  // Проверка правильности ответа
  isRightAnswer(answer: TestItemResult, isPartialCorrect = false): boolean | null {
    if (!this._testItems || !answer.testItemId) {
      return null;
    }
    const testItem = this._testItems[answer.testItemId];

    switch (testItem?.testItemType) {
      case TestItemTypeEnum.FirstClick: {
        const clickedAreas = this.getAnswerValues(answer);
        return (
          !!clickedAreas.length &&
          CompareHelperService.compare(
            {
              isArray: true,
              values: clickedAreas,
            } as OperandInterface,
            {
              isArray: true,
              values: (testItem as Task).correctDictionaryItems?.map((item) => item.fabDictionaryItemEntityId),
            } as OperandInterface,
            OperatorEnum.Intersect,
            false,
          ) !== LogicResultEnum.logicFalse
        );
      }
      case TestItemTypeEnum.FreeForm: {
        if (!(testItem as Task)?.correctAnswerPatterns?.length) {
          return true;
        }

        const isAnswerRight = CompareHelperService.compare(
          {
            isArray: false,
            values: this.getAnswerValues(answer),
          } as OperandInterface,
          {
            isArray: false,
            values: [((testItem as Task).correctAnswerPatterns || []).join('|')],
          } as OperandInterface,
          this.getCompareRightAnswerOperator((testItem as Task).compareType),
          false,
        );
        return isAnswerRight !== LogicResultEnum.logicUndefined ? isAnswerRight === LogicResultEnum.logicTrue : null;
      }
      case TestItemTypeEnum.Number: {
        const isAnswerRight = CompareHelperService.compare(
          {
            isArray: false,
            values: this.getAnswerValues(answer),
          } as OperandInterface,
          {
            isArray: false,
            periodValue: {
              from: (testItem as Task)?.correctAnswerMin ?? null,
              to: (testItem as Task)?.correctAnswerMax ?? null,
            } as DiapasonValue,
            values: [],
          } as OperandInterface,
          OperatorEnum.Contained,
          false,
        );
        return isAnswerRight !== LogicResultEnum.logicUndefined ? isAnswerRight === LogicResultEnum.logicTrue : null;
      }
      case TestItemTypeEnum.Select: {
        if (!(testItem as Task).correctDictionaryItems || !(testItem as Task).correctDictionaryItems?.length) {
          return !isPartialCorrect;
        }

        let isAnswerRight: boolean | null = null;

        if ((testItem as Task).answerQuantity === RowAnswerQuantityEnum.Single) {
          //радиобаттоны
          isAnswerRight =
            !isPartialCorrect &&
            CompareHelperService.compare(
              {
                isArray: true,
                values: this.getAnswerValues(answer),
              } as OperandInterface,
              {
                isArray: true,
                values: (testItem as Task).correctDictionaryItems?.map((item) => item.fabDictionaryItemEntityId),
              } as OperandInterface,
              OperatorEnum.Contained,
              false,
            ) === LogicResultEnum.logicTrue;
        } else {
          // чекбоксы
          isAnswerRight =
            CompareHelperService.compare(
              {
                isArray: true,
                values: this.getAnswerValues(answer),
              } as OperandInterface,
              {
                isArray: true,
                values: (testItem as Task).correctDictionaryItems?.map((item) => item.fabDictionaryItemEntityId),
              } as OperandInterface,
              OperatorEnum.Equal,
              false,
            ) === LogicResultEnum.logicTrue;

          if (isPartialCorrect) {
            isAnswerRight =
              !isAnswerRight &&
              CompareHelperService.compare(
                {
                  isArray: true,
                  values: this.getAnswerValues(answer),
                } as OperandInterface,
                {
                  isArray: true,
                  values: (testItem as Task).correctDictionaryItems?.map((item) => item.fabDictionaryItemEntityId),
                } as OperandInterface,
                OperatorEnum.Intersect,
                false,
              ) === LogicResultEnum.logicTrue;
          }
        }

        return isAnswerRight;
      }
      default: {
        return null;
      }
    }
  }

  private getCompareRightAnswerOperator(compareType?: CompareTypeEnum): OperatorEnum {
    switch (compareType) {
      case CompareTypeEnum.Equal:
        return OperatorEnum.Equal;
      case CompareTypeEnum.Contain:
        return OperatorEnum.Contained;
      case CompareTypeEnum.Regexp:
        return OperatorEnum.Regex;
      default:
        return OperatorEnum.Contained;
    }
  }
}
