<form [formGroup]="formAnswer">
  <ng-container formArrayName="formRows">
    <p-table [columns]="columns" [value]="rows" [autoLayout]="true" responsiveLayout="scroll" styleClass="xl:ml-8 lg:ml-6 sm:ml-0">
      <ng-template pTemplate="body" let-row let-columns="columns" let-i="rowIndex">
        <tr [formGroupName]="i" class="flex flex-column md:flex-row align-items-center">
          <td class="inline-block border-none pl-0 text-left w-full md:text-right xl:w-30rem lg:w-25rem md:w-16rem">
            <ng-template [ngTemplateOutlet]="answerTemplateRef" [ngTemplateOutletContext]="{ col: row }"></ng-template>
          </td>
          <td
            #starsContainer
            class="border-none text-center relative inline-block w-full md:w-8"
            [attr.colspan]="columns.length"
            style="padding-left: 0"
          >
            <!-- Оценки -->
            <div class="flex justify-content-start align-items-center" *ngIf="scaleData$ | async as scaleDataValue">
              <ngx-stars
                #stars
                [maxStars]="scaleDataValue.maxValue"
                (ratingOutput)="onRatingSet($event - 1, row?.entityId)"
                [customStarIcons]="iconUrlsMap.get(row.ratingType)"
                [customPadding]="getIconPadding(starsContainer.clientWidth)"
                [wholeStars]="scaleDataValue.interaction === interactionEnum.Discrete"
                [color]="iconColorMap.get(row.ratingType)"
                [initialStars]="getInitialStars(row?.entityId, stars)"
              ></ngx-stars>

              <span *ngIf="scaleDataValue.showValueEnabled" class="ml-3">
                {{ getValue(getRating(row?.entityId)) }} {{ scaleDataValue.unitOfMeasurement }}
              </span>
            </div>
            <!-- /Оценки -->
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
</form>

<ng-template #answerTemplateRef let-col="col">
  <span>
    <span class="mr-2" *ngIf="col?.imageUrl && col?.imageUrl.length">
      <app-image [url]="col?.imageUrl" [dialog]="true"></app-image>
    </span>
    <span class="text-left" [innerHTML]="col?.captionRu ?? '&nbsp;' | safeHtml"></span>
  </span>
</ng-template>
