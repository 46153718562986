import { isNil } from 'lodash';
import { environment } from 'src/environments/environment';
import { Environment } from '../models/environment';

export function setDynamicEnvironment(): void {
  if (!isDynamicEnvironmentSpecified()) {
    return;
  }

  environment.production = window.dynamicEnvironment.production;
  environment.apiUrl = window.dynamicEnvironment.apiUrl;
  environment.apiVersion = window.dynamicEnvironment.apiVersion;
  environment.clientLogsApiUrl = window.dynamicEnvironment.clientLogsApiUrl;
  environment.kvStorageApiUrl = window.dynamicEnvironment.kvStorageApiUrl;
  environment.moderatedApiUrl = window.dynamicEnvironment.moderatedApiUrl;
  environment.telemetryApiUrl = window.dynamicEnvironment.telemetryApiUrl;
  environment.androidAppPageUrl = window.dynamicEnvironment.androidAppPageUrl;
  environment.iosAppPageUrl = window.dynamicEnvironment.iosAppPageUrl;
  environment.mobileAppScheme = window.dynamicEnvironment.mobileAppScheme;
  environment.androidModeratedAppPageUrl = window.dynamicEnvironment.androidModeratedAppPageUrl;
  environment.iosModeratedAppPageUrl = window.dynamicEnvironment.iosModeratedAppPageUrl;
  environment.mobileModeratedAppScheme = window.dynamicEnvironment.mobileModeratedAppScheme;
  environment.extensionUrl = window.dynamicEnvironment.extensionUrl;
  environment.logo = window.dynamicEnvironment.logo;
  environment.favicon = window.dynamicEnvironment.favicon;
  environment.language = window.dynamicEnvironment.language;
  environment.title = window.dynamicEnvironment.title;
}

function isDynamicEnvironmentSpecified(): boolean {
  if (isNil(window.dynamicEnvironment)) {
    return false;
  }

  return Object.values(window.dynamicEnvironment).every((value: Environment[keyof Environment]) => {
    if (typeof value === 'string') {
      return !value.startsWith('${');
    }
    return true;
  });
}
