import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FabDictionaryItem } from 'src/api/testrunner/models/fab-dictionary-item';
import { FabDictionaryItemStateEnum } from 'src/api/testrunner/models/fab-dictionary-item-state-enum';
import { ScaleTypeEnum } from 'src/api/testrunner/models/scale-type-enum';
import { Task } from 'src/api/testrunner/models/task';

interface Emotion {
  title: string;
  icon: string;
}

@Component({
  selector: 'app-scale-emotions',
  templateUrl: './scale-emotions.component.html',
  styleUrls: ['./scale-emotions.component.scss'],
})
export class ScaleEmotionsComponent {
  @Input() public columns: Array<FabDictionaryItem> = [];
  @Input() public formAnswer!: UntypedFormGroup;
  @Input() public rows: Array<FabDictionaryItem> = [];
  @Input() public scaleData!: Task;
  @Input() public emotions: Emotion[] = [];

  @Output() public readonly _onRatingSet = new EventEmitter<{ rating: number; entityId: string }>();

  private get colDictionaryLength(): number | undefined {
    return this.columns?.filter((item) => item.state === FabDictionaryItemStateEnum.Item).length;
  }

  public get isEmotionsColor(): boolean {
    return this.scaleData?.scaleType === ScaleTypeEnum.EmotionsColor;
  }

  public get filteredEmotions(): Emotion[] {
    let indexes: number[] = [];
    switch (this.colDictionaryLength) {
      case 2:
        indexes = [2, 4];
        break;
      case 3:
        indexes = [2, 3, 4];
        break;
      case 5:
        indexes = [1, 2, 3, 4, 5];
        break;
      default:
        indexes = [0, 1, 2, 3, 4, 5, 6];
        break;
    }

    return this.emotions.filter((_: Emotion, index: number) => {
      return indexes.indexOf(index) !== -1;
    });
  }

  public getColDictionaryItemByIndex(index: number): FabDictionaryItem | undefined {
    const colDictionary = this.columns?.filter((item) => item.state === FabDictionaryItemStateEnum.Item);
    return colDictionary ? colDictionary[index] : undefined;
  }

  public getRating(entityId: string): number | null {
    let value: number | null = 0;
    this.formAnswer.getRawValue().formRows.forEach((item: { [key: string]: number | null }) => {
      const entry = Object.entries(item)[0];
      if (entry[0] === entityId) {
        value = entry[1];
      }
    });

    return value ?? null;
  }

  public onRatingSet(rating: number, entityId: string): void {
    this._onRatingSet.emit({ rating, entityId });
  }
}
